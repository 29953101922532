import { alpha, styled } from '@mui/material';
// ? Material Ui
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// ? Next js
import Image from 'next/image';
import React from 'react';
import { API } from '_helpers';

const Item = styled(Paper)(({ theme }) => ({
  '&:hover': {
    boxShadow: '0 15px 25px 0 rgba(0,0,0,0.1)',
    background: 'rgba(255, 255, 255, 0.1)',
  },
  padding: theme.spacing(4),
}));

interface IWhyChooseUs extends IWithCards {
  media: IMedia;
}

const WhyChooseUs: React.FC<IProps<IWhyChooseUs>> = ({ data }) => {
  return (
    <Box
      sx={{
        bgcolor: (theme) =>
          theme.palette.mode === 'dark'
            ? `${alpha(theme.palette.action.active, 0.02)}`
            : theme.palette.grey.A200,
        py: 12,
      }}
    >
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Stack spacing={2}>
              <Typography
                component="h2"
                color="text.primary"
                variant="h2"
                gutterBottom
              >
                {data.title}
              </Typography>
              <Typography
                color="text.secondary"
                component="div"
                className="content"
                dangerouslySetInnerHTML={{
                  __html: data.description,
                }}
              />
            </Stack>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                width: '85%',
                m: {
                  xs: 'auto',
                  md: 0,
                },
              }}
            >
              <Image
                alt={data.media.alternativeText}
                src={`${API}${data.media.url}`}
                width={data.media.width}
                height={data.media.height}
                priority
              />
            </Stack>
          </Grid>
          <Grid item container spacing={4} xs={12} md={6}>
            <Grid item xs={12} md={6}>
              <Stack spacing={4} sx={{ mt: 10 }}>
                {data.cards.slice(0, 2).map((el, i) => (
                  <Item variant="outlined" sx={{ p: 2 }} key={i}>
                    <Stack justifyContent="center" alignItems="center">
                      <Image
                        src={`${API}${el.media.url}`}
                        alt={el.media.alternativeText}
                        width={64}
                        height={64}
                        quality={100}
                      />
                      <Typography
                        component="h3"
                        variant="h3"
                        sx={{ my: 2 }}
                        textAlign="center"
                      >
                        {el.title}
                      </Typography>
                      <Typography textAlign="center">
                        {el.description}
                      </Typography>
                    </Stack>
                  </Item>
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={4}>
                {data.cards.slice(2, 4).map((el, i) => (
                  <Item variant="outlined" sx={{ p: 2 }} key={i}>
                    <Stack justifyContent="center" alignItems="center">
                      <Image
                        src={`${API}${el.media.url}`}
                        alt={el.media.alternativeText}
                        width={64}
                        height={64}
                        quality={100}
                      />
                      <Typography
                        component="h3"
                        variant="h3"
                        sx={{ my: 2 }}
                        textAlign="center"
                      >
                        {el.title}
                      </Typography>
                      <Typography textAlign="center">
                        {el.description}
                      </Typography>
                    </Stack>
                  </Item>
                ))}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default WhyChooseUs;
